.blog-markdown {
  display: grid;
}

.blog-markdown img {
  width: 100%;
}

.blog-markdown p {
  margin-top: 1em;
  white-space: pre-wrap;
}

.blog-markdown blockquote {
  margin: 0;
  white-space: pre-wrap;
  font-style: italic;
  border-left: 5px solid #eee;
  padding: 0px 20px;
}

.blog-markdown h1 { 
  display: block;
  font-size: 2em;
  margin-top: 0.67em;
  margin-bottom: 0.67em;
  margin-left: 0;
  margin-right: 0;
  font-weight: bold;
}

.blog-markdown h2 {
  display: block;
  font-size: 1.5em;
  margin-top: 0.83em;
  margin-bottom: 0.83em;
  margin-left: 0;
  margin-right: 0;
  font-weight: bold;
}

.blog-markdown h3 { 
  display: block;
  font-size: 1.17em;
  margin-top: 1em;
  margin-bottom: 1em;
  margin-left: 0;
  margin-right: 0;
  font-weight: bold;
}

.blog-markdown h4 { 
  display: block;
  font-size: 1em;
  margin-top: 1.33em;
  margin-bottom: 1.33em;
  margin-left: 0;
  margin-right: 0;
  font-weight: bold;
}

.blog-markdown h5 { 
  display: block;
  font-size: .83em;
  margin-top: 1.67em;
  margin-bottom: 1.67em;
  margin-left: 0;
  margin-right: 0;
  font-weight: bold;
}

.blog-markdown h6 { 
  display: block;
  font-size: .67em;
  margin-top: 2.33em;
  margin-bottom: 2.33em;
  margin-left: 0;
  margin-right: 0;
  font-weight: bold;
}

.fb_dialog {
  /*bottom: 32px !important;*/
  /*right: 9pt !important;*/
  z-index: 9999 !important;
}

.fb_iframe_widget iframe {
  /*right: 0pt !important;*/
  z-index: 9999 !important;
}

.ant-notification {
  z-index: 9999 !important;
}

.ant-alert-info {
  background-color: #e6f7ff !important;
  border: 1px solid #91d5ff;
}

.ant-alert-success {
  background-color: #f6ffed !important;
  border: 1px solid #b7eb8f;
}

.ant-alert-error {
  background-color: #fff2f0 !important;
  border: 1px solid #ffccc7;
}

.ant-alert-warning {
  background-color: #fffbe6 !important;
  border: 1px solid #ffe58f;
}
